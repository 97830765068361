import CardProject from "../../components/CardProject";
import "../../style/css/Home.css";
import "../../style/css/HomeSmartphone.css";
import {Link} from "react-router-dom";
import dataProject from '../../data/dataProject.json';
import React, { useState, useEffect } from 'react';
import imgprofile from "../../img/profile.avif"
import imagebooki from '../../img/cardsHome/Booki.avif'
import imageOhmyfood from '../../img/cardsHome/OhMyfood.avif'
import imagekasa from '../../img/cardsHome/kasa.avif'
import imagekanap from '../../img/cardsHome/kanap.avif'
import imageLaPanthere from '../../img/cardsHome/LaPanthere.avif'
import imagelightup from '../../img/cardsHome/lighthup.avif'
import imagepiiquante from '../../img/cardsHome/piiquante.avif'


function useBougeMaTete(){
    const [bougeMaTete , setBougeMaTete] = useState('');
    useEffect(() => {
        let pageHeight = document.documentElement.clientHeight;
        let prevScrollPos = window.pageYOffset;
        const aboutMePosition = () =>{
            const currentScrollPos = window.pageYOffset;
            const dPH = pageHeight*2.25; // dBH = double page height

            if (currentScrollPos<dPH+50){
                setBougeMaTete('rouleAGauche');
            }
            else if(currentScrollPos>=dPH+50 && currentScrollPos<dPH+250 && prevScrollPos<=dPH+50){
                setBougeMaTete('rouleADroite');
            }else if(currentScrollPos>=dPH+250 && prevScrollPos<=dPH+250){
                setBougeMaTete('petitSaut');
            }else if(currentScrollPos<dPH+250 && currentScrollPos>=dPH+50 && prevScrollPos>dPH+250){
                setBougeMaTete('grosseTete');
            }else if(currentScrollPos>=dPH+450 && prevScrollPos<=dPH+450){
                setBougeMaTete('pos2');
            }else if(currentScrollPos<dPH+450 && currentScrollPos>=dPH+250 && prevScrollPos>dPH+450) {
                setBougeMaTete('resetpos2');
            }else if(currentScrollPos>=dPH+650 && prevScrollPos<=dPH+650){
                setBougeMaTete('pos3');
            }else if(currentScrollPos<dPH+650 && currentScrollPos>=dPH+450 && prevScrollPos>dPH+650) {
                setBougeMaTete('resetpos3');
            }else if(currentScrollPos>=dPH+850 && prevScrollPos<=dPH+850){
                setBougeMaTete('pos4');
            }else if(currentScrollPos<dPH+850 && currentScrollPos>=dPH+650 && prevScrollPos>dPH+850) {
                setBougeMaTete('resetpos4');
            }else if(currentScrollPos>=dPH+1050 && prevScrollPos<=dPH+1050){
                setBougeMaTete('pos5');
            }else if(currentScrollPos<dPH+1050 && currentScrollPos>=dPH+850 && prevScrollPos>dPH+1050) {
                setBougeMaTete('resetpos5');
            }else if(currentScrollPos>=dPH+1250 && prevScrollPos<=dPH+1250){
                setBougeMaTete('pos6');
            }else if(currentScrollPos<dPH+1250 && currentScrollPos>=dPH+1050 && prevScrollPos>dPH+1250) {
                setBougeMaTete('resetpos6');
            }
            prevScrollPos = currentScrollPos;


        };
        window.addEventListener('scroll', aboutMePosition);
        return () => {
            window.removeEventListener('scroll', aboutMePosition);
        };
    },[]);
    return bougeMaTete;
}
function Home() {
    function separateLettersAndSpaces(str) {
        const lettersAndSpaces = str.split("");
        return lettersAndSpaces.map((letterOrSpace, index) => (
            <span className={"letter"} key={index}>{letterOrSpace}</span>
        ));
    }
    const positonInPage= useBougeMaTete();
    let prev="";
    let next="";
    const [stateOfProject,setStateOfProject] =useState('');
    const totalElements = dataProject.length;
    const [childNumber, setChildNumber] = useState(1);
    const delayArrow=199;
    const handleClickRightArrow = () => {
        if (childNumber>=totalElements){
            setTimeout(() => {
                setChildNumber(1);
            }, delayArrow);
            setChildNumber(1);
        }
        else{
            setTimeout(() => {
                setChildNumber(childNumber+1);
            }, delayArrow);
        }
        setStateOfProject("suivant");
        setTimeout(() => {
            setStateOfProject("");
        }, delayArrow);
    };
    const handleClickLeftArrow = () => {
        if (childNumber<=1){
            setTimeout(() => {
                setChildNumber(totalElements);
            }, delayArrow);
        }
        else{
            setTimeout(() => {
                setChildNumber(childNumber-1);
            }, delayArrow);
        }
        setStateOfProject("precedent");
        setTimeout(() => {
            setStateOfProject("");
        }, delayArrow);
    };
    const cards = [];
    let i = -1;
    while (i < dataProject.length-1) {
        let item
        if(i+childNumber>=totalElements){
            item = dataProject[i+childNumber-totalElements];
        }else{
            item = dataProject[i+childNumber];
        }
        cards.push(
            <CardProject
                key={i}
                id={item.id}
                title={item.title}
                description={item.description}
                img={item.image}
                lien={item.link}
            />
        );
        if (i===0){
            next=item.title;
        }
        if(i<dataProject.length-1){
            prev=item.title;
        }
        i++;
    }


    return (
            <main className={"home"}>
                <section id={"accueil"}>
                    <h1 className="word">
                        {separateLettersAndSpaces("HUGO DEPOUMPS")}
                        {/*<span>HUGO </span>
                        <span>DEPOUMPS</span>*/}
                    </h1>
                    <div className={"lien"}>
                        <Link to={"https://github.com/hdepoumps"} target="_blank">GitHub</Link>
                        <Link to={"https://www.linkedin.com/in/hugo-depoumps/" } target="_blank">Linkedin</Link>
                    </div>
                </section>
                <section id={"project"} className={stateOfProject}>
                    <h2 className={"next left"}>{next}</h2>
                    <h2 className={"actual left"}>{dataProject[childNumber-1].title}</h2>
                    <h2 className={"prev left"}>{prev}</h2>
                    <i onClick={handleClickLeftArrow} className="fa-solid fa-arrow-left"></i>
                    <div className={"affichagedesprojets"}>
                        {cards}
                    </div>
                    <i onClick={handleClickRightArrow} className="fa-solid fa-arrow-right"></i>
                    <h2 className={"prev right"}>{prev}</h2>
                    <h2 className={"actual right"}>{dataProject[childNumber-1].title}</h2>
                    <h2 className={"next right"}>{next}</h2>
                    {/*
                    Début de l'animation lors de l'appuie sur la fleche
                    Fin de l'animation= changement de variable appliquer et retour a la premiere frame
                    mais avec du coup un décalage */}
                </section>
                <section id={"about"}>
                    <div className={`${positonInPage} bio`}>
                        <div className={"imgabout"}>
                            <img src={imgprofile} alt={"ma tête"}/>
                        </div>
                        <p>Je m'appelle Hugo Depoumps</p>
                        <p>Je suis développeur web</p>
                        <div className={"pointByPointBar"}>
                            <div className={"bar1"}></div>
                            <div className={"bar2"}></div>
                        </div>
                        <p>J'ai 21 ans, depuis des années je suis passionné par tout ce qui est créatif.</p>
                        <p>En 2020, je me lance dans le montage vidéo (de façon amateur) et je réalise 4 premiers "Best of" de la chaine youtube <Link to="https://www.youtube.com/@KSWINNIIE" className={"linkYT"} target="_blank">KSWINNIIE</Link>.</p>
                        <p>De 2019 à 2022, j'ai étudié en licence en informatique.</p>
                        <p>Suite à cette formation, j'ai décidé de me former de manière plus concrète : j'ai suivi une formation de développeur web avec OpenClassrooms.</p>
                        <p>J'utilise régulièrement les langages HTML, CSS et JS. Je travaille également avec Node.JS, MongoDB, React et SASS.</p>
                        <p>Actuellement j'étudie à l'EPSI et je recherche une entreprise pour mon alternance.</p>
                    </div>

                </section>
            </main>
    );
}

export default Home;
