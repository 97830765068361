import '../../style/css/CardProject.css'
import { Link } from 'react-router-dom'

function cardProject(props) {

    return(
        <Link to={props.lien} target="_blank" className={"lienCardProject"}>
            <div className={"cardProject"}>
                <img src={props.img} alt={"illustration de la page web de " + props.title}/>
                <p className={"describe"}>{props.description}</p>
                <p>{props.title}</p>
            </div>
        </Link>
    );
}
export default cardProject;