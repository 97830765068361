import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../../style/css/Header.css';

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState('');

    useEffect(() => {
        let prevScrollPos = window.pageYOffset;

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollPos > currentScrollPos || currentScrollPos<60)  {
                setScrollDirection('up');
            } else {
                setScrollDirection('down');
            }
            prevScrollPos = currentScrollPos;
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return scrollDirection;
}



function Header() {
    const scrollDirection = useScrollDirection();
    const [stateOfNav, setStateOfNav] = useState('hideNavPhone');

    const clickButton = () => {
        setStateOfNav(stateOfNav === 'hideNavPhone' ? '' : 'hideNavPhone');
    };

    return (
        <header className={scrollDirection === 'down' ? 'hide' : ''}>
            <div onClick={clickButton} className={`${stateOfNav} burger`}>
                <div className={"burger1"}></div>
                <div className={"burger1"}></div>
                <div className={"burger1"}></div>
            </div>
            <nav  className={`${stateOfNav}`}>
                <a href={"#accueil"}>Accueil</a>
                <a href={"#project"}>Mes projets</a>
                <a href= {"#about"}>A propos de moi</a>
                <Link to="https://www.linkedin.com/in/hugo-depoumps/">Contact</Link>
            </nav>
        </header>
    );
}

export default Header;
